///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Simple resume page with static content

		TODO:
            Nothing

	*/


///////////////////////////////
// Imports
///////////////////////////////

	// React
	import {
		useEffect,
		useState,
		useReducer,
	} from 'react'
	// import {useParams} from 'react-router-dom'

	// Config

	// Context

	// MUI Icons

	// MUI Components
	import {
		Box,
		Card,
		CircularProgress,
		Divider,
		Typography,
	} from '@mui/material/'

	// Models

	// Components

	// Services

	// Third Party
	import { Trans } from 'react-i18next'
	import {
		DocumentData,
		DocumentReference,
		doc,
		onSnapshot,
		getFirestore,
		setDoc,
		Unsubscribe,
	} from 'firebase/firestore'

	// CSS


///////////////////////////////
// Types
///////////////////////////////

	interface ResumeEducationInterface {
		institution: string
		location: string
		degree: string
		minor: string
		dates: string
		graduation: string
		comments: string[]
	}

	interface ResumePositionsInterface {
		title: string,
		dates: string,
		comments: string[]
	}

	interface ResumeExperienceInterface {
		company: string,
		location: string,
		positions: ResumePositionsInterface[]
	}

	interface ResumeDataInterface {
		header: {
			name: string
			[key: string]: string
		}
		education: ResumeEducationInterface[]
		experiences: ResumeExperienceInterface[]
		skills: string[]
	}

	interface SetDataCallbackInterface { (data: any): void }

///////////////////////////////
// Variables
///////////////////////////////

	// Displayed Translatable Strings
	const s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE: JSX.Element = 				<Trans>Failed to save document to database</Trans>

///////////////////////////////
// Functions
///////////////////////////////

	// Resume Data Endpoint
	const DatabaseRefResumeDocument = (): DocumentReference<DocumentData> => {
		return doc(getFirestore(), "temp", "resume")
	}

	const DatabaseGetLiveDocument = (documentRef: DocumentReference<DocumentData>, setDataCallback: SetDataCallbackInterface): Unsubscribe => {
		let data: any = {}
		const unsubscribeLiveData = onSnapshot( documentRef, (documentSnapshot) => {
				// let source = documentSnapshot.metadata.hasPendingWrites ? 'Local' : 'Server'
				data = documentSnapshot.data()
				setDataCallback(data)
			}, (error) => {
				console.error(error)
			}
		)
		return unsubscribeLiveData
		// WARNING - Make sure to call unsubscribeLiveData() on component unmount
	}

	const DatabaseSetReplaceDocument = async (documentRef: DocumentReference<DocumentData>, updateObject: any): Promise<unknown> => {
		return new Promise((resolve, reject) => {
			try {
				setDoc(documentRef, updateObject, { merge: false }).then(() => {
					resolve({
						success: true,
					})
				}).catch((firestoreDatabaseError) => {
					console.error(firestoreDatabaseError)
					reject({
						success: false,
						error: {
							message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
							details: firestoreDatabaseError.message,
							code: "ER-D-SDM-DSRD-01",
						}
					})
				})
			} catch (err: any) {
				reject({
					success: false,
					error: {
						message: s_FAILED_TO_SAVE_DOCUMENT_TO_DATABASE,
						details: err.message,
						code: "ER-D-SDM-DSRD-02",
					}
				})
			}
		})
	}

	// const resumeInitialData = {
		// header: {
		// 	name: "John Morrell",
		// 	p1: "208",
		// 	p2: "201",
		// 	p3: "7710",
		// 	e1: "john.",
		// 	e2: "morrell.",
		// 	e3: "if@gma",
		// 	e4: "il.com"
		// },
		// education: [
		// 	{
		// 		institution: "Brigham Young University",
		// 		location: "Provo, Utah",
		// 		degree: "BS Biochemistry",
		// 		minor: "Business Management Minor",
		// 		dates: "Aug 2010 - Apr 2014",
		// 		graduation: "Graduation April 2014",
		// 		comments: [
		// 			"3.82 GPA"
		// 		]
		// 	}
		// ],
		// experiences: [
		// 	{
		// 		company: "LOADCALL / FLEET LOGISTICS PRO",
		// 		location: "Provo, Utah",
		// 		positions: [
		// 			{
		// 				title: "Cofounder & Senior Software Developer",
		// 				dates: "Jul 2016 - present",
		// 				comments: [
		// 					"Founded a startup software company that offers a SAAS product for companies that haul commodities",
		// 					"Designed, built, and maintained an enterprise level web application and associated database / server",
		// 					"Product offers solutions for managing hiring, compliance, dispatch, reporting, accounting, and more",
		// 					" Software is actively being used to manage the transportation of ~40,000 barrels of oil per day",
		// 				]
		// 			}
		// 		]
		// 	},
		// 	{
		// 		company: "QUALTRICS",
		// 		location: "Provo, Utah",
		// 		positions: [
		// 			{
		// 				title: "Operations Analyst",
		// 				dates: "Jul  2015 - Jul 2016",
		// 				comments: [
		// 					"Worked with several different support teams to make sure that client service levels were outstanding",
		// 					"Created the closed loop transactional customer satisfaction process that Qualtrics still uses to this day",
		// 					"Managed dozens of high profile projects both in operations department and throughout the company",
		// 					"Built several internal software applications to increase employee efficiency and accountability",
		// 				]
		// 			},
		// 			{
		// 				title: "Product Specialist",
		// 				dates: "May 2014 - Jul 2015",
		// 				comments: [
		// 					"Led support teams for corporate clients, mobile support, theme design, and a new dashboard product",
		// 					"Contributed to multiple high impact projects that involved personnel from different teams",
		// 					"Consistently led as one of the top performers for answering support phone calls and emails",
		// 				]
		// 			},
		// 		]
		// 	},
		// 	{
		// 		company: "PROSELEM USA / FREELANCE WORK",
		// 		location: "Mapleton, Utah",
		// 		positions: [
		// 			{
		// 				title: "Graphic Designer",
		// 				dates: "Jun 2012 - Apr 2014",
		// 				comments: [
		// 					"Learned Photoshop and Illustrator while I worked to create content for Proselem through Jan 2013",
		// 					"Subsequently worked with several other small companies and organizations to make book covers, advertisements, business cards, T-shirts, website content, etc",
		// 				]
		// 			}
		// 		]
		// 	},
		// 	{
		// 		company: "BRIGHAM YOUNG UNIVERSITY",
		// 		location: "Provo, Utah",
		// 		positions: [
		// 			{
		// 				title: "Chemistry Teaching Assistant",
		// 				dates: "Aug 2011 - Apr 2014",
		// 				comments: [
		// 					"Worked part time for two and a half years teaching undergraduate students general chemistry",
		// 					"Taught lectures, supervised laboratory classes, held office hours, and worked in a walk in help lab",
		// 					"Described in a student review as “definitely knows the material and is good at teaching it in ways that the students can understand”",
		// 				]
		// 			},
		// 			{
		// 				title: "Research Assistant",
		// 				dates: "Aug 2011 - Apr 2014",
		// 				comments: [
		// 					"Performed research for the Howard Hughes Medical Institute Phage Hunters Research Program",
		// 					"Discovered and purified a new strain of mycobacterium virus whose genome is published on Genbank",
		// 					"Developed a massive excel spreadsheet application to analyze tens of thousands of viral genes",
		// 				]
		// 			},
		// 		]
		// 	},
		// 	// {
		// 	// 	company: "",
		// 	// 	location: "",
		// 	// 	positions: [
		// 	// 		{
		// 	// 			title: "",
		// 	// 			dates: "",
		// 	// 			comments: [
		// 	// 				"",
		// 	// 			]
		// 	// 		},
		// 	// 	]
		// 	// },
		// ],
		// skills: [
		// 	"Full Stack Web Development (Javascript/Typescript, React, Angular, Node, Firebase, Google Cloud)",
		// 	"Project and Product Management",
		// 	"User Interface and User Experience Design",
		// 	"Data collection, analysis and visualization (Qualtrics, Excel, SQL, Tableau, PowerPoint, C3, D3)",
		// 	"Graphic Design (Photoshop, Illustrator)",
		// 	"Experienced teacher, mentor, presenter and leader",
		// 	"Ability to work in extreme ambiguity and pick up new skills quickly",
		// ]
	// }

///////////////////////////////
// Container
///////////////////////////////

	export const ResumeContainer: React.FC = (): JSX.Element => {

		// Props
		// const params = useParams()

		// Hooks - useContext, useState, useReducer, other
		const [ resumeData, setResumeData ] = 				useState<ResumeDataInterface | null>(null)
		const forceRerender = 								useReducer(() => ({}), {})[1] as () => void

		// Hooks - useEffect
		useEffect(() => {

			let unsubscribeLiveData: () => void
			const updateLiveData = (newData: any) => {
				setResumeData(newData)
				forceRerender()
			}
			unsubscribeLiveData = DatabaseGetLiveDocument( DatabaseRefResumeDocument(), updateLiveData )

			// DatabaseSetReplaceDocument(DatabaseRefResumeDocument(), resumeInitialData)

			// setResumeData(resumeInitialData)

			return () => {
				if(typeof unsubscribeLiveData === 'function'){
					unsubscribeLiveData()
				}
			}
		}, [forceRerender])


		// Other Variables

		// Functions

		// Call Functions

		// JSX Generation
		const returnPageJSX = (): JSX.Element => {
			let pageJSX = <></>
			if(resumeData != null){
				pageJSX =
				<Box className="tw-box-border tw-p-2 tw-bg-gradient-to-b tw-from-blue-500 via-indigo-700 tw-to-indigo-800 tw-w-full tw-h-fit tw-mt-0">
					<Box className="tw-w-full">
						<Card className="tw-p-3 tw-w-auto tw-max-w-6xl tw-container tw-mx-auto">
							<Box>
								<Typography variant="h5" className="tw-font-bold tw-text-center">{resumeData.header.name}</Typography>
								<Typography variant="subtitle1" className="tw-text-center">
									{resumeData.header.p1}-{resumeData.header.p2}-{resumeData.header.p3} | {resumeData.header.e1}{resumeData.header.e2}{resumeData.header.e3}{resumeData.header.e4}
									</Typography>
							</Box>
							<Box className="tw-clear-both tw-mt-6">
								<Typography variant="h6" className="tw-font-bold tw-text-left tw-uppercase">Education</Typography>
								<Divider></Divider>
								{resumeData.education.map(( school: ResumeEducationInterface, schoolIndex: number ) => (
									<Box key={schoolIndex}>
										<Box className="tw-clear-both">
											<Typography variant="subtitle1" className="tw-float-left tw-font-bold tw-uppercase">{school.institution}</Typography>
											<Typography variant="subtitle1" className="tw-float-right">{school.location}</Typography>
										</Box>
										<Box className="tw-clear-both tw-ml-2">
											<Typography variant="subtitle1" className="tw-float-left tw-italic">{school.degree}</Typography>
											<Typography variant="subtitle1" className="tw-float-right tw-italic">{school.dates}</Typography>
										</Box>
										<Box className="tw-clear-both tw-ml-2">
											<Typography variant="subtitle1" className="tw-float-left tw-italic">{school.minor}</Typography>
											<Typography variant="subtitle1" className="tw-float-right tw-italic">{school.graduation}</Typography>
										</Box>
										<Box className="tw-clear-both tw-ml-2">
											{school.comments.map(( comment: string, commentIndex: number ) => (
												<Typography key={commentIndex} variant="subtitle1" className="">▪ {comment}</Typography>
											))}
										</Box>
									</Box>
								))}
							</Box>
							<Box className="tw-clear-both tw-mt-6">
								<Typography variant="h6" className="tw-font-bold tw-text-left tw-uppercase">Experience</Typography>
								<Divider></Divider>
								{resumeData.experiences.map(( experience: ResumeExperienceInterface, experienceIndex: number ) => (
									<Box key={experienceIndex}>
										<Box className="tw-clear-both">
											<Typography variant="subtitle1" className="tw-float-left tw-font-bold tw-uppercase">{experience.company}</Typography>
											<Typography variant="subtitle1" className="tw-float-right">{experience.location}</Typography>
											{experience.positions.map(( position: ResumePositionsInterface, positionIndex: number ) => (
												<Box className="tw-clear-both tw-ml-2 tw-mb-3" key={positionIndex}>
													<Typography variant="subtitle1" className="tw-float-left tw-italic">{position.title}</Typography>
													<Typography variant="subtitle1" className="tw-float-right tw-italic">{position.dates}</Typography>
													{position.comments.map(( comment: string, commentIndex: number ) => (
														<Box className="tw-clear-both tw-ml-2" key={commentIndex}>
															<Typography key={commentIndex} variant="subtitle1" className="tw-ml-2">▪ {comment}</Typography>
														</Box>
													))}
												</Box>
											))}
										</Box>
									</Box>
								))}
							</Box>
							<Box className="tw-clear-both tw-mt-6">
								<Typography variant="h6" className="tw-font-bold tw-text-left tw-uppercase">Skills</Typography>
								<Divider></Divider>

								{resumeData.skills.map(( skill: string, skillIndex: number ) => (
									<Typography key={skillIndex} variant="subtitle1" className="tw-ml-2">▪ {skill}</Typography>
								))}

							</Box>
						</Card>
					</Box>
				</Box>
			} else {
				pageJSX =
				<Box className="tw-box-border tw-p-2 tw-bg-gradient-to-b tw-from-blue-500 via-indigo-700 tw-to-indigo-800 tw-w-full tw-h-screen tw-mt-0 tw-grid tw-content-around tw-text-center">
					<Box className="tw-m-auto">
						<CircularProgress color="warning"/>
					</Box>
				</Box>
			}
			return pageJSX
		}

		// Render
		return <>{returnPageJSX()}</>
	}

