///////////////////////////////
// Description
///////////////////////////////

	/*
		DESCRIPTION / USAGE:
			Home page has all the routes for the application

		TODO:
			Add in router
			Add in firebase config

	*/

///////////////////////////////
// Imports
///////////////////////////////

	// React
	import {
		BrowserRouter as Router,
		Route,
		Routes,
	} from 'react-router-dom'
	// Config

	// Firebase

	// MUI Icons

	// MUI Components

	// Theme
	import {
		createTheme,
		responsiveFontSizes,
		ThemeProvider
	} from '@mui/material/styles'

	// Context

	// Containers
	import { ResumeContainer } from "./containers/resume/resume"

	// Components

	// Services

	// CSS
	import './App.css'


///////////////////////////////
// Types
///////////////////////////////


///////////////////////////////
// Variables
///////////////////////////////


///////////////////////////////
// Functions
///////////////////////////////

	let theme = createTheme()
	theme = responsiveFontSizes(theme)

///////////////////////////////
// Exports
///////////////////////////////




function App() {
	return (
		<ThemeProvider theme={theme}>
			<Router>
				<Routes>
					<Route path={"/"} element={<ResumeContainer />} />
				</Routes>
			</Router>
		</ThemeProvider>
	);
}

export default App;
